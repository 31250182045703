export default [
  {
    key: 'name',
    label: 'field.name',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'description',
    label: 'field.description',
    type: 'textarea',
  },
  {
    key: 'isEnable',
    label: 'status.active',
    type: 'checkbox',
    cols: 12,
  },
  {
    key: 'abilities',
    label: 'field.permission',
    rules: 'required',
    type: 'permissions',
    cols: 12,
  },
]
